import React from 'react';
import { useSelector } from 'react-redux';

import {
  CompanyLogo,
  Container,
  HeaderGroup,
  NavBar,
  Text,
  TimeLineBlock,
  Section,
  Heading,
  AnchorButton,
  BackgroundVideo,
  StyledMain
} from '@domain/components';

import { isMobile } from 'react-device-detect';
import { generatePageContent } from '@domain/helpers';
import { detectContentType } from '@domain/content';

function NoBodyshopPage() {

  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer)

  const contentType = detectContentType(insurer)
  const content = generatePageContent(contentType, insurer, incident, false, isMobile, 'thank_you', null)

  const title = content["title"]
  const link = content["link"]
  const text = content["text"]
  const endText = content["end-text"]
  const companyMiddleLogo = content["company-middle-logo"]
  const timelineTitle = content["timeline-title"]
  const timeline = content["timeline"]
  const glassTimeline = content["timeline-glass"]

  const activeItem = timeline ? timeline.findIndex(item => item.active) : null

  const timelineIcon = (item, i) => ({
    name: item.icon,
    className: 'timeline__item__icon',
    timeline: true,
    muted: i > activeItem
  });

  const companyLogo = {
    align: companyMiddleLogo ? 'center' : 'left',
    width: '120',
    height: 60,
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
  };


  return (
    <React.Fragment>
      <StyledMain className='main__end'>
        <NavBar className="navbar-end">
          {companyMiddleLogo && <CompanyLogo {...companyLogo} />}
        </NavBar>
        <Section className="page-wizard__content recovery__content">
          <Container className="page-wizard__heading recovery__heading">
            <HeaderGroup>
              <Container className="recovery__info-heading">
                <HeaderGroup>
                  {title && <Heading level="1" >{title}</Heading>}
                  {text && <Text className="recovery__bodyshop-text">{text}</Text>}
                  {link && < Container className="recovery__info--button">
                    <AnchorButton
                      href={link}
                      target="blank"
                      dynamicFontSize={true}
                    >
                      kies uw hersteller
                    </AnchorButton>
                  </Container>}
                  {endText && <Text className="recovery__bodyshop-text"><br />{endText}</Text>}
                </HeaderGroup>
              </Container>
            </HeaderGroup>
          </Container>
          <Container className="page-wizard__timeline recovery__timeline">
            {timelineTitle && <Heading level={'4'} className={"timeline--heading"}>{timelineTitle}</Heading>}
            {timeline && <TimeLineBlock timeline={incident.damageType === 'Glas' ? glassTimeline : timeline} activeItem={activeItem} timelineIcon={timelineIcon} bodyshop={null} />}
          </Container>
        </Section>
      </StyledMain>
      <BackgroundVideo
        type="video/mp4"
        loop={true}
        autoPlay={true}
        videoFileName="DEF2.mp4"
        img={true}
        imgFileName="BSSP03.jpg"
        faqVisible={false}
      />
    </React.Fragment>
  );
}

export default NoBodyshopPage;
