import React, { Children } from 'react';

import { styled } from '@mui/material';
import { Colors } from '@domain/theming';
import { useScaling } from '@domain/hooks';
import Main from '../Main';

const StyledDiv = styled(Main, {
    shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  .navbar {
    .company-logo--left {
      max-width: 120px;
    }
    .left-side-logo {
      flex: 1 0 0;
    }
  }
  .page-wizard__content {
    display: flex;
    flex-direction: column;
    padding: 32px 32px 0;
  }
  .page-wizard__heading {
    flex: 1 1 auto;
    .h1 {
      margin: 30px 0 12px;
      word-break: break-word;
    }
  }
  .page-wizard__info {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    .h4 {
    }
    .button {
      margin-top: 15px;
      width: 100%;
      &__contact {
        height: auto;
        text-align: left;
        padding: 24px;
        .header-group {
          margin-right: auto;
        }
        span {
          text-transform: initial;
          font-weight: normal;
        }
        .h5 {
          margin-bottom: 15px;
        }
      }
    }
  }
  .page-wizard__timeline {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 50px 0;
  }
  .page-wizard__button {
    padding: 32px;
    justify-content: stretch;
    button {
      width: 100%;
    }
  }
  
  .recovery__heading {
    .screen-mobile & {
      padding-bottom: 42px;
    }
    .h4 {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .recovery__info--button {
    display:flex;
    flex-direction: column;
    margin-top: 30px;
    .h4 {
      margin-top: 15px;
    }
    .button {
      margin-top: 15px;
      width: 100%;
      &__contact {
        height: auto;
        text-align: left;
        padding: 24px;
        .button-wrapper {
          justify-content: space-between;
        }
        .header-group {
          margin-right: auto;
        }
        span {
          text-transform: initial;
          font-weight: normal;
        }
        .h5 {
          margin-bottom: 15px;
        }
      }
    }
  }
  .recovery__bodyshop-name {
    .screen-mobile & {
      margin-top: 12px;
    }
  }
  .recovery__bodyshop-description {
    margin-bottom: 60px;
  }
  .recovery__timeline {
    .timeline__item {
      // font-weight: normal;
      // padding-bottom: 5.6vh;
      &:before {
        background-color: ${Colors.WHITE};
        opacity: 1;
      }
      &:last-child {
        padding-bottom: 20px;
      }
    }
    .timeline__item--muted {
      color: ${Colors.WHITE};
      opacity: .6;
      &:before {
        background-color: ${Colors.WHITE};
      }
      .timeline__icon {
        background: ${Colors.WHITE};
        color: ${Colors.GREY_DARKER};
      }
    }
    .contact-person-picture {
      width: ${props => props.$scaling.scaleUpTo4K(32)}px;
      height: ${props => props.$scaling.scaleUpTo4K(32)}px;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 8px;
      object-fit: cover;
      object-position: 0 10%;
    }
  }


  .screen-desktop & {
    .page-wizard__content {
      flex-direction: row;
      padding: ${props => props.$scaling.scaleUpTo4K(22)}px ${props => props.$scaling.scaleUpTo4K(40)}px 0;
      align-items: center;
    }
    .page-wizard__heading {
      padding-left: ${props => props.$scaling.scaleUpTo4K(80)}px;
      &.no-coverage {
        padding-left: 0;
      }
      .h1 {
        margin-bottom: 0;
      }
    }
    .page-wizard__text {
      display: block;
      margin-top: ${props => props.$scaling.scaleUpTo4K(20)}px;
    }
    .page-wizard__info {
      flex: 1 1 auto;
      width: 50%;
      height: 50%;
      justify-content: flex-start;
    }
    .page-wizard__timeline {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      padding: 0;
      padding-right: ${props => props.$scaling.scaleUpTo4K(120)}px;
      padding-left: ${props => props.$scaling.scaleUpTo4K(40)}px;
      .timeline {
        // margin-top: auto;
        // padding-top: ${props => props.$scaling.scaleUpTo4K(50)}px;
      }
      .timeline--heading {
        width: 100%;
        max-width: ${props => props.$scaling.scaleUpTo4K(350)}px;
        display: block;
      }
    }
    .page-wizard__button {
      padding: ${props => props.$scaling.scaleUpTo4K(40)}px;
      padding-top: 0;
      button {
        margin-left: auto;
      }
    }
    
    .recovery__content {
      padding-bottom: ${props => props.$scaling.scaleUpTo4K(82)}px;
    }
    .recovery__bodyshop-name {
      margin: ${props => props.$scaling.scaleUpTo4K(15)}px 0 ${props => props.$scaling.scaleUpTo4K(20)}px 0;
    }
    .recovery__bodyshop-description {
      margin-top: ${props => props.$scaling.scaleUpTo4K(20)}px;
      margin-bottom: ${props => props.$scaling.scaleUpTo4K(40)}px;
      .h4 {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(2)}px;
      }
    }
    .recovery__info-heading {
      margin-top: ${props => props.$scaling.scaleUpTo4K(20)}px;
        .no-coverage {
          text-align:center;
        }
    }
    .recovery__info--button {
      flex: 1 1 auto;
      width: 50%;
      height: 50%;
      justify-content: flex-start;
      > div {
        max-width: ${props => props.$scaling.scaleUpTo4K(350)}px;
      }
      .button {
        &__contact {
          padding: ${props => props.$scaling.scaleUpTo4K(24)}px;
          .header-group {
          }
          .h5 {
            margin-bottom: ${props => props.$scaling.scaleUpTo4K(15)}px;
          }
        }
      }
    }
    .page-wizard__heading {
      flex: 2 0 0;
      display: flex;
      flex-direction:column;
      justify-content: center;
    }
    .recovery__timeline {
      flex: 1 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .timeline {
      }
      .h4 {
        margin-bottom: ${props => props.$scaling.scaleUpTo4K(10)}px;
        width: 100%;
        max-width: ${props => props.$scaling.scaleUpTo4K(350)}px;
      }
    }
  }
  
  .theme-custom & {
    .recovery__heading {
      color: ${Colors.WHITE}
      .h4 {
        color: ${props => props.theme.colors.primary};
      }
    }
    .recovery__timeline {
      .timeline__item {
        color: ${Colors.WHITE};
        &:before {
          background: ${Colors.WHITE};
        }
        .timeline__icon {
          color: ${Colors.WHITE};
          background: ${props => props.theme.colors.primary};
        }
      }
      .h4 {
        color: ${props => props.theme.colors.primary};
      }
    }
  }

  .screen-ie & {
    .button__contact {
      max-height: none;
    }
  }

  @media screen and (max-width: 1263px) {
    .screen-desktop & {
      .page-wizard__heading {
        padding-left: 20px;
      }
      .page-wizard__timeline {
        padding-right: 40px;
      }
    }
  }

  @media screen and (max-width: 1028px) {
    .rating {
      margin-top: 30px;
    }
    .screen-desktop & {
      .page-wizard__content {
        flex-direction: column;
        justify-content: flex-start;
      }
      .page-wizard__heading {
        text-align: center;
        padding: 0;
      }
      .page-wizard__info {
        width: 100%;
        .button {
          max-width: 580px;
        }
      }
      .page-wizard__button {
        padding-top: 50px;
      }
      .page-wizard__text {
        width: auto;
        margin: auto;
      }
      .page-wizard__timeline {
        padding: 50px 0;
        .timeline {
        }
      }
      
      .recovery__bodyshop-name {
        margin: 15px auto 20px;
      }
      .recovery__info--button {
        width: 100%;
        text-align: center;
        >div {
          max-width: none;
        }
        .button {
          max-width: 580px;
          margin: 15px auto 0;
          &__contact {
            .header-group {
            }
          }
        }
      }
      .recovery__bodyshop-description {
        margin: 20px auto 0;
      }
    }
  }

  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .page-wizard__button {
        button {
          width: 100%;
        }
      }
    }
  }
    
  .screen-ie & {
    .page-wizard__content {
      flex: 1 0 auto;
      height: auto;
      min-height: 454px;
    }
    .page-wizard__timeline {
    }
    .page-wizard__button {
      flex: 0 0 auto;
      button {
        // margin-top: 40%;
      }
    }
    
    .page-wizard__info {
      .button__contact {
        max-height: none;
      }
    }

    .recovery__timeline-and-button {
      display: block;
    }
  
    .recovery__timeline-and-button {
      .timeline {
        margin-top: 33%;
      }
    }
  }
`;

function StyledMain({ children, ...props }) {
    const scaling = useScaling();
    return <StyledDiv {...props} $scaling={scaling}>{Children.toArray(children)}</StyledDiv>;
}

export default StyledMain;
