import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import {
  BackgroundVideo,
  CompanyLogo,
  Container,
  HeaderGroup,
  Loader,
  NavBar,
  Text,
  TimeLineBlock,
  Section,
  Heading,
  UseIcon,
  Button,
  AnchorButton,
  StyledMain,
} from '@domain/components';
import { companyNames, proccessType } from '@domain/constants';
import { useSaveIncidentFactory } from '@domain/hooks';
import { isMobile } from 'react-device-detect';
import { browserStorage, checkIsFeedback, chooseLogoSize, chooseLogoToBeDisplayed, generatePageContent } from '@domain/helpers';
import { detectContentType } from '@domain/content';
import { useQuestionnaire } from '@domain/providers';
import { useLocation } from 'react-router-dom';


function ThankYou() {

  const hasUpdated = useRef(false);
  const incident = useSelector(state => state.incident);
  const insurer = useSelector(state => state.insurer)
  const bodyshop = useSelector(state => state.bodyshop);

  const { questionnaire } = useQuestionnaire();

  const themeContext = useTheme();

  const isFeedback = checkIsFeedback(incident)
  const isVIP = insurer.objectType === proccessType.Casco && (insurer.brand === companyNames.VIP || !insurer.brand)
  const isThemed = !!insurer && insurer.brand !== companyNames.VIP && insurer.objectType !== proccessType.DI;

  const bodyshopName = bodyshop ? bodyshop.name || bodyshop.companyName : '';
  const bodyshopFound = !!bodyshopName;
  const bodyshopId = incident.carRefinisherUUID || incident.outletUUID || incident.bodyshopID

  const location = useLocation()
  const currentPath = location.pathname;
  const page = questionnaire.find(page => page.path === currentPath)
  const saveBehavior = page ? page.saveBehavior : undefined

  const saveIncident = useSaveIncidentFactory(incident, saveBehavior, 'thank-you');

  const checkForOutlet = () => {
    const bs = incident && incident.outletUUID && bodyshop && bodyshop.outlets ? bodyshop.outlets.find(outlet => outlet.uuid === incident.outletUUID) : null
    if (!!bs) {
      return { ...bodyshop, ...bs }
    }
    return bodyshop
  }


  const finalBodyshop = checkForOutlet()

  useEffect(() => {

    const storageList = Object.keys(localStorage);
    browserStorage.clearLocalStorage([...storageList], ['consentCode', 'bannerOpen', 'uuid']);

    if (isFeedback) {
      if (hasUpdated.current) {
        return;
      }
      hasUpdated.current = true;
    } else if ((incident.status === 'process_aborted' && incident.cancelReason === 'VIP_not_chosen') ||
      (incident.status === 'damage_cancelled' && incident.cancelReason === 'inactivity_from_the_customer')) {
      hasUpdated.current = true;
      const progressStr = 'thank_you'
      saveIncident({ ...incident, portalStatus: 'Done', progressStr });
    } else {
      if (incident.portalStatus !== 'Done') {
        if (hasUpdated.current || incident.status !== '_new') {
          return;
        }
        hasUpdated.current = true;
        const progressStr = 'thank_you'
        saveIncident({ ...incident, portalStatus: 'Done', progressStr });
      }
    }
  }, [incident, isFeedback, questionnaire, saveIncident]);

  if (!questionnaire || !questionnaire.length || !insurer || !incident || (!!incident && bodyshopId && !bodyshopFound)) {
    return <Loader />;
  }

  const contentType = detectContentType(insurer)
  const pageName = questionnaire.find(page => page.path === currentPath).name
  const content = generatePageContent(contentType, insurer, incident, false, isMobile, pageName, bodyshop)

  const title = content["title"]
  const link = content["link"]
  const text = content["text"]
  const feedbackText = content["feedback-text"]
  const endText = content["end-text"]
  const showLeftLogo = content["show-left-logo"]
  const companyMiddleLogo = content["company-middle-logo"]
  const showTimeline = content["show-timeline"]
  const timelineTitle = content["timeline-title"]
  const timeline = content["timeline"]
  const glassTimeline = content["timeline-glass"]
  const showBodyshopBlock = content["show-bodyshop-block"]
  const bodyshopBlockTitle = content["bodyshop-block-title"]
  const glassTitle = content["glass-title"]
  const glassText = content["glass-text"]
  const glassSecondText = content["glass-second-text"]

  const activeItem = timeline ? timeline.findIndex(item => item.active) : null

  const timelineIcon = (item, i) => ({
    name: item.icon,
    className: 'timeline__item__icon',
    timeline: true,
    muted: i > activeItem
  });

  const companyLogo = {
    align: companyMiddleLogo ? 'center' : 'left',
    width: '120',
    height: 60,
    src: insurer.logoURL || '',
    alt: insurer.brandName || 'uw verzekeraar',
  };

  const middleLogo = {
    ...chooseLogoToBeDisplayed(insurer),
    className: isVIP ? 'vip-logo' : '',
    width: '100%',
    height: chooseLogoSize(themeContext.logoSize),
  };

  const handleOnButtonClick = () => {
    const protocol = finalBodyshop.website ? finalBodyshop.website.startsWith('http') : null
    if (protocol) {
      window.open(finalBodyshop.website, '_blank');
    }
    window.open(`http://${finalBodyshop.website}`, '_blank');
  };

  const button = {
    onClick: handleOnButtonClick,
    className: 'button__contact',
    theme: 'secondary',
    justify: 'space-between',
    disabled: !finalBodyshop || (finalBodyshop && !finalBodyshop.website)

  };

  if (incident.coverage === 'declined') {
    return (
      <React.Fragment>
        <StyledMain className="main__end">
          <NavBar className="navbar-end" isVIP={isVIP}>
            {showLeftLogo ? <Container className="left-side-logo"><CompanyLogo {...companyLogo} /></Container> : <Container className="empty-div" />}
            {companyMiddleLogo ? <CompanyLogo {...companyLogo} /> : <CompanyLogo {...middleLogo} />}
            <Container className="empty-div" />
          </NavBar>
          <Section className="page-wizard__content recovery__content">
            <Container className="page-wizard__heading recovery__heading no-coverage">
              <HeaderGroup>
                <Container className="recovery__info-heading no-coverage">
                  <HeaderGroup className="no-coverage">
                    <Heading level="3" >We hebben uw dekking niet kunnen bepalen.</Heading>
                    <Heading level="3" >Uw verzekeraar neemt contact met u op.</Heading>
                    <Heading level="3" >
                      U kunt ook zelf contact opnemen: 088 185 5555.</Heading>
                  </HeaderGroup>
                </Container>
              </HeaderGroup>
            </Container>
          </Section>
        </StyledMain>
        <BackgroundVideo
          type="video/mp4"
          loop={true}
          autoPlay={true}
          videoFileName="DEF2.mp4"
          img={!isVIP || isThemed}
          imgFileName="BSSP03.jpg"
        />
      </React.Fragment>
    );

  }

  if (incident.damageType === 'Glas' && finalBodyshop) {
    return (
      <React.Fragment>
        <StyledMain className="main__end">
          <NavBar className="navbar-end" isVIP={isVIP}>
            {showLeftLogo ? <Container className="left-side-logo"><CompanyLogo {...companyLogo} /></Container> : <Container className="empty-div" />}
            {companyMiddleLogo ? <CompanyLogo {...companyLogo} /> : <CompanyLogo {...middleLogo} />}
            <Container className="empty-div" />
          </NavBar>
          <Section className="page-wizard__content recovery__content">
            <Container className="page-wizard__heading recovery__heading">
              <HeaderGroup>
                {glassTitle && <Heading level="1" >{glassTitle}</Heading>}
                <Container className="recovery__info-heading">
                  <HeaderGroup>
                    {glassText && <Text className="recovery__bodyshop-text">{glassText}</Text>}
                    {glassSecondText && <Text className="recovery__bodyshop-text">{glassSecondText}</Text>}
                    {endText && <Text className="recovery__bodyshop-text"><br />{endText}</Text>}
                  </HeaderGroup>
                </Container>
              </HeaderGroup>
            </Container>
            <Container className="page-wizard__timeline recovery__timeline">
              {(showTimeline && glassTimeline) &&
                <>
                  {timelineTitle && <Heading level={'4'} className={"timeline--heading"}>{timelineTitle}</Heading>}
                  <TimeLineBlock timeline={glassTimeline} activeItem={activeItem} timelineIcon={timelineIcon} bodyshop={finalBodyshop} />
                </>}
            </Container>
          </Section>
        </StyledMain>
        <BackgroundVideo
          type="video/mp4"
          loop={true}
          autoPlay={true}
          videoFileName="DEF2.mp4"
          img={!isVIP || isThemed}
          imgFileName="BSSP03.jpg"
        />
      </React.Fragment>
    );

  }
  return (
    <React.Fragment>
      <StyledMain className="main__end">
        <NavBar className="navbar-end" isVIP={isVIP}>
          {showLeftLogo ? <Container className="left-side-logo"><CompanyLogo {...companyLogo} /></Container> : <Container className="empty-div" />}
          {companyMiddleLogo ? <CompanyLogo {...companyLogo} /> : <CompanyLogo {...middleLogo} />}
          <Container className="empty-div" />
        </NavBar>
        <Section className="page-wizard__content recovery__content">
          <Container className="page-wizard__heading recovery__heading">
            <HeaderGroup>
              <Container className="recovery__info-heading">
                <HeaderGroup>
                  {title && <Heading level="1" >{title}</Heading>}
                  {isFeedback && feedbackText && <Text className="recovery__bodyshop-text">{feedbackText}</Text>}
                  {text && <Text className="recovery__bodyshop-text">{text}</Text>}
                  {showBodyshopBlock && link && !finalBodyshop &&
                    < Container className="recovery__info--button">
                      <AnchorButton
                        href={link}
                        target="blank"
                        dynamicFontSize={true}
                      >
                        kies uw hersteller
                      </AnchorButton>
                    </Container>
                  }
                  {endText && <Text className="recovery__bodyshop-text"><br />{endText}</Text>}
                </HeaderGroup>
                {(showBodyshopBlock && finalBodyshop) &&
                  <Container className="recovery__info--button">
                    {bodyshopBlockTitle && <Heading level="4">{bodyshopBlockTitle}</Heading>}
                    <Button {...button}>
                      <HeaderGroup>
                        <Heading level="5">{bodyshopName}</Heading>
                        <Text>{
                          finalBodyshop.street + ' ' +
                          finalBodyshop.houseNumber + ','
                        }<br />{
                            finalBodyshop.zipcode + ' ' +
                            finalBodyshop.city
                          }</Text>
                      </HeaderGroup>
                      {!button.disabled && <UseIcon name="arrow-right" className="button__icon" />}
                    </Button>
                  </Container>}
              </Container>
            </HeaderGroup>
          </Container>
          <Container className="page-wizard__timeline recovery__timeline">
            {(showTimeline && timeline) &&
              <>
                {timelineTitle && <Heading level={'4'} className={"timeline--heading"}>{timelineTitle}</Heading>}
                <TimeLineBlock timeline={incident.damageType === 'Glas' ? glassTimeline : timeline} activeItem={activeItem} timelineIcon={timelineIcon} bodyshop={finalBodyshop} />
              </>}
          </Container>
        </Section>
      </StyledMain>
      <BackgroundVideo
        type="video/mp4"
        loop={true}
        autoPlay={true}
        videoFileName="DEF2.mp4"
        img={!isVIP || isThemed}
        imgFileName="BSSP03.jpg"
      />
    </React.Fragment>
  );
}

export default ThankYou;
