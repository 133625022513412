import React from "react"

const defaultContent = (inCreation, incident, isMobile, bodyshop, spCase) => {
  const content = {
    welcome: {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": null,
      "first-title": null,
      "second-title": "In vier stappen het beste herstelbedrijf voor uw schade",
      "intro-text": null,
      "show-checklist": true,
      "checklist": [{ icon: "icon-check-circle", text: `Geen verhoogd eigen risico` }, { icon: "icon-check-circle", text: `Gratis haal & breng service én gratis vervangend vervoer` }, { icon: "icon-check-circle", text: `Altijd inzicht in de voortgang van het herstelproces` }],
    },
    "landing": {
      infoBlock: {
        title: `Meld uw schade`,
        'signature-text': null,
        'signature-logo': '',
        'intro-text-paragraphs': [
          { text: 'Vind een aangesloten herstelbedrijf bij u in de buurt.' },
          {
            text:
              'Heeft u auto- of ruitschade? Geen zorgen, met onze bedrijvenzoeker vindt u eenvoudig een herstelbedrijf bij u in de buurt.',
          },
          {
            text:
              'Deze bedrijven zijn allemaal aangesloten bij ons netwerk en voldoen daarom aan hoge kwaliteitseisen.',
          },
          {
            text:
              'Daarnaast levert het u ook voordeel op, zoals korting op uw eigen risico.',
          },
        ],
        'show-intro-text-list': false,
        'intro-text-list': null,
      },
      formBlock: {
        'card-one': {
          button: 'Schadedossier aanmaken',
          title: null,
          subtitle: null,
          paragraphs: null,
          list: null,
          'closing-paragraphs': null,
        },
        'card-two': {
          button: 'Schadedossier aanvullen',
          title: 'Dank voor uw informatie!',
          subtitle: 'We hebben u een bevestigingsmail gestuurd.',
          paragraphs: [
            {
              text:
                'Rond direct uw schadedossier af met onderstaande knop of doe dit op een later moment via de link in de bevestigingsmail.',
            },
          ],
          list: null,
          'closing-paragraphs': null,
        },
      },
    },
    "personal_start": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "title": inCreation ? "Beste klant," : incident && incident.customerFirstName ? `Beste ${incident.customerFirstName} ${incident.customerLastName},` : "Beste klant,",
      "text": 'Hiernaast ziet u de stappen om uw schade volledig te melden.',
      "second-text": "Heeft u alle informatie toegevoegd? Dan stellen wij het meest geschikte herstelbedrijf aan u voor.",
      "third-text": null,
      "show-timeline": true,
      "timeline-title": null,
      "timeline": [{ icon: "user", text: "Klik op “Volgende” om uw persoonlijke gegevens in te vullen." }, { icon: "questionmark", text: "Voeg een beschrijving van de schade toe." }, { icon: "file", text: "Upload vijf foto’s van de schade." }, { icon: "star", text: "U wordt direct gekoppeld aan een herstelbedrijf." }]
    },
    license: {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Persoonlijke gegevens',
      "title": (spCase || !inCreation) ? 'Controleer uw schadedatum' : 'Vul uw kenteken en de schadedatum in',
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn adres nodig?',
          answer: 'Onderdeel van deze dienst is dat we aan de hand van uw adres kunnen bepalen wat het beste herstelbedrijf in uw regio is om uw schade te herstellen. Zij zullen uw auto ook bij u op komen halen.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
        },
        {
          question: 'Hoe komen jullie aan mijn gegevens?',
          answer: 'U heeft de schade bij uw volmacht of verzekeraar gemeld. Dit zijn de gegevens die bekend zijn bij deze volmacht of verzekeraar.',
        }]
    },
    name: {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Persoonlijke gegevens',
      "title": !inCreation ? 'Controleer uw naam' : 'Vul uw naam in',
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn adres nodig?',
          answer: 'Onderdeel van deze dienst is dat we aan de hand van uw adres kunnen bepalen wat het beste herstelbedrijf in uw regio is om uw schade te herstellen. Zij zullen uw auto ook bij u op komen halen.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
        },
        {
          question: 'Hoe komen jullie aan mijn gegevens?',
          answer: 'U heeft de schade bij uw volmacht of verzekeraar gemeld. Dit zijn de gegevens die bekend zijn bij deze volmacht of verzekeraar.',
        }]
    },
    contact: {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Persoonlijke gegevens',
      "title": isMobile ? 'Vul uw e-mailadres en telefoon-nummer in' : 'Vul uw e-mailadres en telefoonnummer in',
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn contactgegevens nodig?',
          answer: 'Als we uw telefoonnummer hebben, kunnen wij dit delen met het herstelbedrijf dat uw auto zal herstellen. Zij zullen u bellen om een afspraak te maken wanneer ze uw auto kunnen ophalen. Met uw e-mailadres kunnen we u op de hoogte houden van de voortgang van het herstel van uw auto.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
        },
        {
          question: 'Hoe komen jullie aan mijn gegevens?',
          answer: 'U heeft de schade bij uw volmacht of verzekeraar gemeld. Dit zijn de gegevens die bekend zijn bij deze volmacht of verzekeraar.',
        }]
    },
    postal: {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Persoonlijke gegevens',
      "title": !inCreation ? 'Controleer uw postcode en huisnummer' : 'Vul uw postcode en huisnummer',
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn adres nodig?',
          answer: 'Onderdeel van deze dienst is dat we aan de hand van uw adres kunnen bepalen wat het beste herstelbedrijf in uw regio is om uw schade te herstellen. Zij zullen uw auto ook bij u op komen halen.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
        },
        {
          question: 'Hoe komen jullie aan mijn gegevens?',
          answer: 'U heeft de schade bij uw volmacht of verzekeraar gemeld. Dit zijn de gegevens die bekend zijn bij deze volmacht of verzekeraar.',
        }]
    },
    address: {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Persoonlijke gegevens',
      "title": 'Controleer uw adres',
      "faqs": [
        {
          question: 'Waarom hebben jullie mijn adres nodig?',
          answer: 'Onderdeel van deze dienst is dat we aan de hand van uw adres kunnen bepalen wat het beste herstelbedrijf in uw regio is om uw schade te herstellen. Zij zullen uw auto ook bij u op komen halen.'
        },
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.'
        },
        {
          question: 'Hoe komen jullie aan mijn gegevens?',
          answer: 'U heeft de schade bij uw volmacht of verzekeraar gemeld. Dit zijn de gegevens die bekend zijn bij deze volmacht of verzekeraar.',
        }]
    },
    gender: {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Persoonlijke gegevens',
      "title": 'Hoe wilt u graag aangesproken worden?',
      "faqs": [
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.',
        },
      ]
    },
    "gender_2": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Persoonlijke gegevens',
      "title": 'Hoe wilt u graag aangesproken worden?',
      "faqs": [
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.',
        },
      ]
    },
    "damage_start": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "title": "Bedankt voor uw persoonlijke gegevens",
      "text": "Klik op “Volgende” om uw schade in een paar stappen te beschrijven.",
      "show-timeline": true,
      "timeline-title": null,
      "timeline": [{ icon: "user", text: "Klik op “Volgende” om uw persoonlijke gegevens in te vullen." }, { icon: "questionmark", text: "Voeg een beschrijving van de schade toe." }, { icon: "file", text: "Upload vijf foto’s van de schade." }, { icon: "star", text: "U wordt direct gekoppeld aan een herstelbedrijf." }]
    },
    "car_owner": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Wie is de eigenaar van de auto?',
      "faqs": [
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.',
        },
      ]
    },
    "wbf_possible": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Heeft u uw auto met schade aangetroffen?',
      "faqs": [
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.',
        },
      ]
    },
    "extra_damage": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Heeft u nog andere oude / meer schades die u ook wilt laten herstellen?',
      "faqs": [
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.',
        },
      ]
    },
    "extra_damage_description": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Beschrijf de overige schade aan uw voertuig',
      "faqs": [
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.',
        },
      ]
    },
    "recoverable_damage": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Wie is er aansprakelijke voor schade?',
      "faqs": [
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.',
        },
      ]
    },
    "paying_damage": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Wie betaalt de schade?',
      "faqs": [
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.',
        },
      ]
    },
    "leasing_company": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Bij welke leasemaatschappij bent u aangesloten?',
      "button-text": "Volgende",
      "faqs": [
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.',
        },
      ]
    },
    "insurance_type": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Hoe bent u verzekerd?',
      "button-text": "Volgende",
      "faqs": [
        {
          question: 'Met wie worden mijn gegevens gedeeld?',
          answer: 'Uw gegevens worden uitsluitend gedeeld met partijen die onderdeel zijn van het herstelproces van uw auto – de volmacht en eventueel tussenpersoon, het herstelbedrijf en een expert indien van toepassing.',
        },
      ]
    },
    "damage_cause_category": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Hoe is de schade ontstaan?',
      "faqs": [
        {
          question: 'Waarom moet ik opgeven hoe de schade is ontstaan?',
          answer: 'Dit is informatie die we met uw verzekeraar moeten delen en is daarom altijd onderdeel van een schadedossier. De verzekeraar deelt schades standaard in in verschillende groepen.'
        },
        {
          question: 'Wat betekenen de verschillende oorzaken precies?',
          answer: (<React.Fragment>
            <dl>
              <dt>Aanrijding met voertuig - </dt>
              <dd>
                U heeft een aanrijding gehad met een ander gemotoriseerd voertuig, dit
                kan bijvoorbeeld een auto, busje, vrachtwagen of scooter zijn.
              </dd>
              <br />
              <br />
              <dt>Aanrijding met voetganger/fiets - </dt>
              <dd>
                U heeft een aanrijding gehad met een niet-gemotoriseerd voertuig zoals
                een fiets of een voetganger.
              </dd>
              <br />
              <br />
              <dt>Aanrijding met dier - </dt>
              <dd>
                U heeft een aanrijding gehad met een dier op de weg, bijvoorbeeld een
                hond, hert of gans.
              </dd>
              <br />
              <br />
              <dt>Aanrijding met (vast) object - </dt>
              <dd>
                U heeft bijvoorbeeld een aanrijding gehad met een hekje, een vangrail
                of een bord langs de weg.
              </dd>{' '}
              <br />
              <br />
              <dt>Inbraak - </dt>
              <dd>
                Er is in uw auto ingebroken en er zijn eigendommen van u uit de auto
                gestolen of er zijn onderdelen van uw auto uit uw auto gestolen.
              </dd>{' '}
              <br />
              <br />
              <dt>Vandalisme - </dt>
              <dd>Uw auto is (opzettelijk) beschadigd aan binnen- of buitenkant.</dd>
              <br />
              <br />
              <dt>Hagel/storm - </dt>
              <dd>
                Uw auto heeft schade opgelopen als gevolg van een hagelbui of een
                storm.
              </dd>
              <br />
              <br />
              <dt>Brand - </dt>
              <dd>
                Uw auto heeft schade opgelopen als gevolg van een brand in of in de
                buurt van uw auto.
              </dd>
            </dl>
          </React.Fragment>
          ),
        },
      ]
    },
    "damage_cause_dropdown": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Hoe is de schade ontstaan?',
    },
    "damage_cause_combined_dropdown": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Hoe is de schade ontstaan?',
    },
    "vehicle_mileage": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": "Wat is je kilometerstand? ",
    },
    "damage_number": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": "Wat is het schadenummer? ",
    },
    "counterparty_known": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Is de tegenpartij bekend?',
      "faqs": [
        {
          question: 'Waarom moet ik de gegevens van de tegenpartij opgeven?',
          answer: 'Deze informatie helpt ons met het eventuele verhalen van uw schade. Indien nodig nemen wij ook contact op met de tegenpartij voor extra informatie.',
        },
      ]
    },
    "animal_owner_known": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Is de eigenaar van het dier bekend?',
      "faqs": [
        {
          question: 'Waarom moet ik de gegevens van de eigenaar opgeven?',
          answer: 'Deze informatie helpt ons met het eventuele verhalen van uw schade. Indien nodig nemen wij ook contact op met de tegenpartij voor extra informatie.',
        },
      ]
    },
    "car_driver": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Persoonlijke gegevens',
      "title": 'Was u zelf de bestuurder ten tijde van de aanrijding/ongeval?',
    },
    "driver_name": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Persoonlijke gegevens',
      "title": 'Wat is de naam van de bestuurder?',
    },
    "driver_contact": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Persoonlijke gegevens',
      "title": 'Vul het e-mailadres en telefoonnummer van de bestuurder in',
    },
    "driver_postal": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Persoonlijke gegevens',
      "title": 'Vul de postcode en huisnummer van de bestuurder in',
    },
    "driver_address": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Persoonlijke gegevens',
      "title": 'Controleer het adres van de bestuurder',
    },
    "driver_birthday": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Persoonlijke gegevens',
      "title": 'Wat is de geboortedatum van de bestuurder?',
    },
    "alcohol_involved": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Was er sprake van alcoholgebruik of andere middelen die de rijvaardigheid van de bestuurder kunnen beinvloeden?',
    },
    "police_present": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Is er politie bij het ongeval geweest?',
    },
    "witness_known": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Is de getuige bekend?',
      "faqs": [
        {
          question: 'Waarom moet ik de gegevens van de getuige opgeven?',
          answer: 'Deze informatie helpt ons met het eventuele verhalen van uw schade. Indien nodig nemen wij ook contact op met de tegenpartij voor extra informatie.'
        },
      ]

    },
    "witness_name": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Vul de naam van de getuige in',
    },
    "witness_address": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Controleer het adres van de getuige',
    },
    "witness_postal": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Vul de postcode en huisnummer van de getuige in',
    },
    "witness_contact": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Vul het e-mailadres en telefoonnummer van de getuige in',
    },
    speed: {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Wat was uw snelheid tijdens het incident?',
      "faqs": [
        {
          question: 'Waarom is het belangrijk om mijn snelheid te weten?',
          answer: 'Het is belangrijk voor het herstelbedrijf om te weten hoe hard u ongeveer reed tijdens het incident. Op deze manier kunnen zij de impact van uw schade beter inschatten.'
        },
      ]
    },
    interior: {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Is de auto aan de binnenkant beschadigd?',
      "faqs": [
        {
          question: 'Wat wordt precies bedoeld met de binnenkant?',
          answer: 'Hiermee willen we weten of uw auto door de impact van het incident ook van binnen beschadigd is. Bijvoorbeeld een verwrongen dorpel bij het openen van het portier. Wij bedoelen bijvoorbeeld niet een vlek op de bekleding.',
        },
      ]
    },
    exterior: {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Waar is uw auto beschadigd?',
      "text": 'Selecteer één of meerdere plekken op de auto. Klik vervolgens op de "volgende" knop om verder te gaan.',
      "faqs": [
        {
          question: 'Wat als mijn auto op meerdere plekken beschadigd is?',
          answer: 'U kunt meerdere vakken aangeven waar uw auto beschadigd is.',
        },
        {
          question: 'Ik kan de plek waar mijn auto is beschadigd niet aangeven, wat moet ik nu doen?',
          answer: 'Arceer het vlak dat het meest in de buurt is van uw schade. U kunt dit later telefonisch toelichten wanneer het herstelbedrijf u zal bellen om een afspraak te maken voor herstel.'
        },
      ]
    },
    "driveable_damage": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Kunt u nog met de auto rijden?',
      "faqs": [{
        question: 'Waarom moet ik opgeven hoe de schade is ontstaan?',
        answer: 'Dit is informatie die we met uw verzekeraar moeten delen en is daarom altijd onderdeel van een schadedossier. De verzekeraar deelt schades standaard in in verschillende groepen.'
      }]
    },
    "created_by_dealer": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Heeft u deze schade gemeld op locatie bij de dealer?',
      "faqs": [{
        question: 'Waarom moet ik opgeven hoe de schade is ontstaan?',
        answer: 'Dit is informatie die we met uw verzekeraar moeten delen en is daarom altijd onderdeel van een schadedossier. De verzekeraar deelt schades standaard in in verschillende groepen.'
      }]
    },
    "car_info": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Controleer uw voertuiggegevens',
      "faqs": [{
        question: 'Waarom moet ik opgeven hoe de schade is ontstaan?',
        answer: 'Dit is informatie die we met uw verzekeraar moeten delen en is daarom altijd onderdeel van een schadedossier. De verzekeraar deelt schades standaard in in verschillende groepen.'
      }]
    },
    "glass_damage_sort": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Gaat het om een Ster of een Scheur?',
      "faqs": [{
        question: 'Waarom moet ik opgeven hoe de schade is ontstaan?',
        answer: 'Dit is informatie die we met uw verzekeraar moeten delen en is daarom altijd onderdeel van een schadedossier. De verzekeraar deelt schades standaard in in verschillende groepen.'
      }]
    },
    "damage_type": {
      infoBlock: {
        title: `Meld uw schade`,
        'signature-text': null,
        'signature-logo': '',
        'intro-text-paragraphs': [
          { text: 'Vind een aangesloten herstelbedrijf bij u in de buurt.' },
          {
            text:
              'Heeft u auto- of ruitschade? Geen zorgen, met onze bedrijvenzoeker vindt u eenvoudig een herstelbedrijf bij u in de buurt.',
          },
          {
            text:
              'Deze bedrijven zijn allemaal aangesloten bij ons netwerk en voldoen daarom aan hoge kwaliteitseisen.',
          },
          {
            text:
              'Daarnaast levert het u ook voordeel op, zoals korting op uw eigen risico.',
          },
        ],
        'show-intro-text-list': false,
        'intro-text-list': null,
      },
      formBlock: {
        'card-one': {
          button: 'Schadedossier aanmaken',
          title: null,
          subtitle: null,
          paragraphs: null,
          list: null,
          'closing-paragraphs': null,
        },
        'card-two': {
          button: 'Schadedossier aanvullen',
          title: 'Dank voor uw informatie!',
          subtitle: 'We hebben u een bevestigingsmail gestuurd.',
          paragraphs: [
            {
              text:
                'Rond direct uw schadedossier af met onderstaande knop of doe dit op een later moment via de link in de bevestigingsmail.',
            },
          ],
          list: null,
          'closing-paragraphs': null,
        },
      },
    },
    "uploads_start": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "title": "Bedankt voor het beschrijven van uw schade.",
      "text": "Klik op “Volgende” om vijf foto’s van de schade te uploaden.",
      "show-timeline": true,
      "timeline-title": null,
      "timeline": [{ icon: "user", text: "Klik op “Volgende” om uw persoonlijke gegevens in te vullen." }, { icon: "questionmark", text: "Voeg een beschrijving van de schade toe." }, { icon: "file", text: "Upload vijf foto’s van de schade." }, { icon: "star", text: "U wordt direct gekoppeld aan een herstelbedrijf." }]
    },
    "images": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Foto’s en documenten',
      "title": "Foto's van uw schade",
      "mobile-text": "Klik hier of op het vraagteken rechtsboven voor instructies.",
      "intro-text": "Wij hebben minimaal 5 foto’s van uw schade nodig: van een afstandje, dichtbij, onder een schuine hoek en van het dashboard. Let op een goede belichting!",
      "second-text": "Zie de instructies hieronder. Klik op de tekst voor uitleg",
      "max-img-size": 10,
      "max-number-images": 30,
      "instructions": [{ title: 'Overzichtsfoto', explanation: 'Ga 1 tot 1,5 meter van uw auto staan. Maak een foto van de schade waarop uw hele auto te zien is.' }, { title: 'Detailfoto vooraanzicht', explanation: 'Ga 0,5 tot 1 meter van uw auto staan en recht voor de schade.' }, { title: 'Detailfoto hoek', explanation: 'Ga 0,5 tot 1 meter van uw auto staan. Fotografeer de schade vanuit een hoek.' }, { title: 'Foto binnenkant auto', explanation: 'Als u bijvoorbeeld schade bij een van de portieren of aan de achterklep heeft, open deze en maak een foto van de binnenkant.' }, { title: 'Foto dashboard', explanation: 'Maak een foto van het dashboard als de motor draait (alle lampjes zijn aan). Het is belangrijk dat de kilometerstand goed zichtbaar is.' }],
      "faqs": [
        {
          question: 'Waarom hebben jullie foto’s van mijn schade nodig?',
          answer: 'Op basis van de foto’s kan het herstelbedrijf een inschatting maken hoe groot de schade is en hoe lang zij nodig hebben om deze te herstellen.'
        },
        {
          question: 'Wat doen jullie met de foto’s van mijn auto?',
          answer: 'Wij delen deze foto’s met het herstelbedrijf zodat zij de schade beter kunnen inschatten.',
        }],
      "validation-text": 'Voeg alstublieft minimaal vijf foto’s toe'
    },
    "guided_images": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Foto’s en documenten',
      "title": "Foto's van uw schade",
      "mobile-text": "Wij hebben minimaal 6 foto’s van uw schade nodig van een afstandje, dichtbij en onder een schuine hoek. Let op een goede belichting!",
      "intro-text": "Wij hebben minimaal 6 foto’s van uw schade nodig van een afstandje, dichtbij en onder een schuine hoek. Let op een goede belichting!",
      "max-img-size": 10,
      "max-number-images": 30,
      "instructions": [{ title: 'Overzichtsfoto', explanation: 'Ga 1 tot 1,5 meter van uw auto staan. Maak een foto van de schade waarop uw hele auto te zien is.', example: '1' }, { title: 'Detailfoto vooraanzicht', explanation: 'Ga 0,5 tot 1 meter van uw auto staan en recht voor de schade.', example: '2' }, { title: 'Detailfoto hoek', explanation: 'Ga 0,5 tot 1 meter van uw auto staan. Fotografeer de schade vanuit een hoek.', example: '3' }, { title: 'Foto binnenkant auto', explanation: 'Als u bijvoorbeeld schade bij een van de portieren of aan de achterklep heeft, open deze en maak een foto van de binnenkant.', example: '4' }, { title: 'Foto dashboard', explanation: 'Maak een foto van het dashboard als de motor draait (alle lampjes zijn aan). Het is belangrijk dat de kilometerstand goed zichtbaar is.', example: '5' }],
      "faqs": [
        {
          question: 'Waarom hebben jullie foto’s van mijn schade nodig?',
          answer: 'Op basis van de foto’s kan het herstelbedrijf een inschatting maken hoe groot de schade is en hoe lang zij nodig hebben om deze te herstellen.'
        },
        {
          question: 'Wat doen jullie met de foto’s van mijn auto?',
          answer: 'Wij delen deze foto’s met het herstelbedrijf zodat zij de schade beter kunnen inschatten.',
        }],
      "validation-text": 'Voeg alstublieft minimaal zes foto’s toe'
    },
    "extra_damage_images": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Foto’s en documenten',
      "title": "Voeg foto’s toe van de overige schade aan uw voertuig",
      "max-img-size": 10,
      "max-number-images": 30,
      "faqs": [
        {
          question: 'Waarom hebben jullie foto’s van mijn schade nodig?',
          answer: 'Op basis van de foto’s kan het herstelbedrijf een inschatting maken hoe groot de schade is en hoe lang zij nodig hebben om deze te herstellen.'
        },
        {
          question: 'Wat doen jullie met de foto’s van mijn auto?',
          answer: 'Wij delen deze foto’s met het herstelbedrijf zodat zij de schade beter kunnen inschatten.',
        }],
      "validation-text": 'Voeg alstublieft minimaal één foto toe'
    },
    "green_card": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Foto’s en documenten',
      "title": "Foto van uw groene kaart",
      "mobile-text": <React.Fragment>Klik <u>hier</u> of op het vraagteken rechtsboven voor een groene kaart voorbeeld.</React.Fragment>,
      "intro-text": "Maak een foto of screenshot van uw groene kaart en zorg dat uw polisgegevens duidelijk leesbaar zijn.",
      "second-text": <React.Fragment><b>Let op:</b>: zonder polisgegevens kan het herstelbedrijf niet voor u aan de slag!</React.Fragment>,
      "extra-block-text": "Klik op het voorbeeld om deze te vergroten",
      "faqs": [{
        question: 'Waar kan ik mijn polisgegevens vinden?',
        answer: 'Met het afsluiten van uw verzekering heeft u een ‘Groene kaart’ ontvangen. Ondanks de naam kan deze ook een andere kleur hebben. Afhankelijk van uw verzekeraar heeft u deze op papier of digitaal ontvangen.'
      },
      {
        question: 'Waarom moet ik mijn polisgegevens bijvoegen?',
        answer: 'Het herstelbedrijf heeft uw polisnummer nodig om de juiste gegevens bij uw verzekeraar op te halen. Zonder  bevestiging van de verzekeraar kan het herstelbedrijf niet starten met het herstellen van uw schade.'
      },
      {
        question: 'Wat gebeurt er als ik mijn polisgegevens niet bijvoeg?',
        answer: 'Het herstelbedrijf zal u er later alsnog om moeten vragen. Om uw tijd én dat van het herstelbedrijf niet te verspillen vragen we u om uw polisgegevens nu al bij het dossier te voegen.'
      },
      ],
      "validation-text": 'Voeg alstublieft minimaal een bestand toe'
    },
    "licenseplate_register": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Foto’s en documenten',
      "title": "Foto van uw kentekenbewijs",
      "mobile-text": <React.Fragment>Klik <u>hier</u> of op het vraagteken rechtsboven voor een kentekenbewijs voorbeeld.</React.Fragment>,
      "intro-text": <React.Fragment>Upload een foto van de <b>achterkant</b> van uw kentekenbewijs (vetgedrukt op het voorbeeld hieronder). Zorg dat uw chassisnummer duidelijk leesbaar is.</React.Fragment>,
      "extra-block-text": "Klik op het voorbeeld om deze te vergroten",
      "faqs": [{
        question: 'Wat is het chassisnummer?',
        answer: 'Het chassisnummer is een unique identificatie code die bij uw auto hoort. Met deze code kan het herstelbedrijf de specificaties van uw auto terug vinden en de onderdelen bestellen de horen bij uw auto, model en type.'
      },
      {
        question: 'Waarom moet ik het chassisnummer bijvoegen?',
        answer: 'Met het chassisnummer kan het herstelbedrijf alvast onderdelen bestellen voor uw auto zodat deze klaar liggen als het herstel begint. Hiermee heeft u sneller uw auto weer terug!'
      },
      ],
      "validation-text": 'Voeg alstublieft minimaal een bestand toe'
    },
    "policy_number": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Vul uw polisnummer in',
      "faqs": [{
        question: 'Waar kan ik mijn polisgegevens vinden?',
        answer: 'Met het afsluiten van je verzekering heb je een ‘Groene kaart’ ontvangen. Ondanks de naam kan deze ook een andere kleur hebben. Afhankelijk van jouw verzekeraar heb je deze op papier of digitaal ontvangen.'
      },
      {
        question: 'Waarom moet ik mijn polisgegevens bijvoegen?',
        answer: 'Het herstelbedrijf heeft jouw polisnummer nodig om de juiste gegevens bij jouw verzekeraar op te halen. Zonder  bevestiging van de verzekeraar kan het herstelbedrijf niet starten met het herstellen van jouw schade.'
      },
      {
        question: 'Wat gebeurt er als ik mijn polisgegevens niet bijvoeg?',
        answer: 'Het herstelbedrijf zal je er later alsnog om moeten vragen. Om jouw tijd én dat van het herstelbedrijf niet te verspillen vragen we je om jouw polisgegevens nu al bij het dossier te voegen.'
      },
      ]
    },

    saf: {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Foto’s en documenten',
      "title": "Schadeformulier",
      "intro-text": "Heeft u een ingevuld schadeformulier? Voeg deze dan toe.",
      "second-text": "Klik op “Volgende” als u geen schadeformulier wilt toevoegen.",
      "intro-text-required": "Upload hier uw schadeformulier.",
      "second-text-required": "Klik op volgende zodra het schadeformulier is geüpload.",
      "faqs": [
        {
          question: 'Waarom zijn er additionele documenten nodig?',
          answer: 'Door documenten zoals een ingevuld schadeaangifteformulier te uploaden kunnen we sneller en beter het schadevoorval beoordelen.',

        },
        {
          question: 'Moet het document een bepaald bestandstype zijn?',
          answer: 'Alle veelgebruikte documenttypes zijn toegestaan.',
        }
      ],
      "validation-text": 'Voeg alstublieft minimaal een bestand toe'
    },
    "police_report": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Foto’s en documenten',
      "title": "Proces verbaal",
      "intro-text": "Als u in het bezit bent van een proces verbaal dan kunt u deze hier uploaden.",
      "second-text": "",
      "intro-text-required": "Upload hier het proces verbaal.",
      "second-text-required": "Klik op volgende zodra het proces verbaal is geüpload.",
      "validation-text": 'Voeg alstublieft minimaal een bestand toe'
    },
    otherfiles: {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Foto’s en documenten',
      "title": "Documenten (optioneel)",
      "intro-text": "Heeft u relevante documenten die van belang kunnen zijn voor het afwikkelen van uw schade? Voeg deze dan toe.",
      "second-text": "Klik op “Volgende” als u geen documenten wil toevoegen.",
      "faqs": [
        {
          question: 'Waarom zijn er additionele documenten nodig?',
          answer: 'Door documenten zoals een ingevuld schadeaangifteformulier te uploaden kunnen we sneller en beter het schadevoorval beoordelen.',

        },
        {
          question: 'Moet het document een bepaald bestandstype zijn?',
          answer: 'Alle veelgebruikte documenttypes zijn toegestaan.',
        }
      ],
      "validation-text": 'Voeg alstublieft minimaal een bestand toe'
    },
    "ownbodyshop_start": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "title": "Bedankt voor het uploaden van de foto’s.",
      "text": "Klik op “Volgende” om de offerte van uw herstelbedrijf toe te voegen. De contactgegevens het herstelbedrijf kunt u ook invullen.",
      "show-timeline": true,
      "title-no-image": null,
      "timeline-title": "Wat moet u doen",
      "timeline": [{ icon: "user", text: "Klik op “Volgende” om uw persoonlijke gegevens in te vullen." }, { icon: "questionmark", text: "Voeg een beschrijving van de schade toe." }, { icon: "file", text: "Upload vijf foto’s van de schade." }, { icon: "invoice", text: "Voeg de offerte en contactgegevens van uw herstelbedrijf toe." }]
    },
    "bodyshop_select": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Kies een bedrijf',
    },
    "bodyshop_select_intakeplus": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": 'Kies een bedrijf',
    },
    "bodyshop_confirm": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schade beschrijven',
      "title": `${bodyshop ? bodyshop.companyName : ""}`,
      "text": "Klik op volgende om uw keuze definitief te maken."
    },
    "bodyshop_selectem": {
      "use-custom-logo": false,
      "over-title-text": 'Contactgegevens en offerte van uw',
      "title": "Herstelbedrijf",
      "custom-help-link": false,
      "intro-text": "Vul de contactgegevens van uw herstelbedrijf in zodat wij eventueel contact op kunnen nemen.",
    },
    offer: {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Contactgegevens en offerte',
      "title": "Offerte",
      "intro-text": "Upload de offerte of calculatie van uw herstelbedrijf en vul het totale reparatiebedrag in.",
      "second-text": "Let op: het is belangrijk dat u een complete en goed leesbare offerte of calculatie uploadt",
    },
    "service_advisor_name": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Serviceadviseur',
      "title": 'Naam service adviseur en vestiging',
    },
    "comment": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "over-title-text": 'Schadebeschrijving',
      "title": 'Opmerkingen',
    },
    "recovery_start": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "title": "U heeft alle informatie toegevoegd",
      "text": "Klik op “Volgende” om uw dossier af te ronden. We stellen het herstelbedrijf meteen aan u voor.",
      "show-timeline": true,
      "show-extra-block": false,
      "extra-block-title": null,
      "extra-block-auxiliar-text": null,
      "extra-button-text": null,
      "show-checklist": false,
      "timeline-title": null,
      "timeline": [{ icon: "user", text: "Klik op “Volgende” om uw persoonlijke gegevens in te vullen." }, { icon: "questionmark", text: "Voeg een beschrijving van de schade toe." }, { icon: "file", text: "Upload vijf foto’s van de schade." }, { icon: "star", text: "U wordt direct gekoppeld aan een herstelbedrijf." }]
    },
    "thank_you": {
      "use-custom-logo": false,
      "custom-help-link": false,
      "title": incident ? `Beste ${incident.customerFirstName} ${incident.customerLastName}` : "Beste klant",
      "text": "Wij hebben uw schademelding in goede orde ontvangen en zullen zo snel mogelijk contact met u opnemen.",
      "feedback-text": "We hebben de fotos in goede orde ontvangen en zullen binnen 1 werkdag contact met u opnemen. Bedankt",
      "end-text": "U kunt deze pagina sluiten.",
      "show-left-logo": false,
      "no-bodyshop-text": null,
      "link": null,
      "show-bodyshop-block": false,
      "bodyshop-block-title": "Informatie",
      "company-middle-logo": true,
      "show-timeline": true,
      "timeline-title": null,
      "glass-text": null,
      "timeline": [{ icon: "user", text: "Uw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }]
    },
    success: {
      "use-custom-logo": false,
      "custom-help-link": false,
      "show-faq": false,
      "show-left-logo": false,
      "company-middle-logo": true,
      "show-rating": false,
      "title": incident ? `Beste ${incident.customerFirstName} ${incident.customerLastName}` : "Beste klant",
      "show-info-block": false,
      "info-block-title": null,
      "info-block-text": null,
      "show-bodyshop-block": false,
      "bodyshop-block-title": null,
      "feedback-text": "Bedankt. We hebben de fotos in goede orde ontvangen en zullen binnen 1 werkdag contact met u opnemen.",
      "text": `${isMobile ? 'Hieronder' : 'Hiernaast'} vindt u een overzicht van de vervolgstappen om het gehele proces snel en professioneel af te ronden.`,
      "second-text": null,
      "second-text-no-track-trace": null,
      "third-text": null,
      "failed-text": `Beste ${incident ? incident.customerFirstName : "klant"} ${incident ? incident.customerLastName : ""}, helaas bieden wij op dit moment nog geen hersteller binnen uw postcode gebied. U kunt daarom geen gebruik van deze dienst. Neem contact op met uw verzekeringsadviseur voor het afhandelen van uw schade.`,
      "end-text": "U kunt deze pagina sluiten.",
      "show-timeline": true,
      "timeline-title": null,
      "timeline-show-check": false,
      "timeline": [{ icon: "user", text: "Uw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
      "feedback-timeline": [{ icon: "mail", text: "Schade beschrijven." }, { icon: "user", text: bodyshop && (bodyshop.name || bodyshop.companyName) ? `U word zo spoedig mogelijk gebeld door ${bodyshop ? bodyshop.name || bodyshop.companyName : "uw hersteller"} ` : `U wordt zo spoedig mogelijk gebeld`, active: true }, { icon: "towtruck", text: "Uw auto wordt gratis opgehaald." }, { icon: "wrench", text: bodyshop && (bodyshop.name || bodyshop.companyName) ? `${bodyshop ? bodyshop.name || bodyshop.companyName : "uw hersteller"} herstelt uw auto.` : `Uw auto wordt hersteld` }, { icon: "car-returned", text: "Uw auto wordt gratis bij u teruggebracht." }],
      "failed-timeline": null,
      "spcase-failed-timeline": null,
      "show-background": true,
    },
    end: {
      "use-custom-logo": false,
      "show-left-logo": false,
      "company-middle-logo": true,
      "title": incident ? `Beste ${incident.customerFirstName} ${incident.customerLastName}` : "Beste klant",
      "show-bodyshop-block": false,
      "feedback-text": "Bedankt. We hebben de fotos in goede orde ontvangen en zullen binnen 1 werkdag contact met je opnemen.",
      "text": `${isMobile ? 'Hieronder' : 'Hiernaast'} vindt je een overzicht van de vervolgstappen om het gehele proces snel en professioneel af te ronden.`,
      "second-text": null,
      "third-text": null,
      "end-text": "Je kunt deze pagina sluiten.",
      "show-timeline": true,
      "timeline-title": null,
      "timeline-show-check": false,
      "timeline": [{ icon: "user", text: "Jouw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
      "feedback-timeline": [{ icon: "user", text: "Jouw persoonlijke gegevens invullen." }, { icon: "questionmark", text: "Beschrijving van de schade toevoegen." }, { icon: "file", text: "Upload foto's van de schade en benodigde documenten." }, { icon: "star", text: "Wij nemen zo snel mogelijk contact op.", active: true }],
      "failed-timeline": null,
      "spcase-failed-timeline": null,
      "show-background": true,
    },
    "end_of_flow": {
      "use-custom-logo": false,
      "show-left-logo": false,
      "company-middle-logo": true,
      "title": null,
      "show-bodyshop-block": false,
      "opening-text": "Geachte heer/mevrouw,",
      "text": `Op de vorige pagina heeft u aangegeven dat het niet meer mogelijk is om met uw voertuig te rijden nadat u schade heeft opgelopen door een hagelbui. Helaas is het dan niet mogelijk om uw schademelding via deze weg af te ronden.`,
      "second-text": 'Om uw voertuig te laten herstellen adviseren wij u om met de alarmdienst van uw verzekeraar contact op te nemen. Zij kunnen ervoor zorgen dat er een berger wordt ingeschakeld om uw voertuig op te halen en deze weg te brengen naar een hersteller binnen het Schadegarant netwerk.',
      "third-text": "Mocht u hier nog vragen over hebben dan kunt u hierover contact opnemen met uw verzekeraar.",
      "closing-text": "Met vriendelijke groet,",
      "signature": "eXperience.",
      "show-background": false,
    },

  }
  return content
}

export default defaultContent