import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';

// Domain imports.
import {
  AttachmentViewer,
  Button,
  CompanyLogo,
  Container,
  FAQ,
  GridColumn,
  GridContainer,
  Heading,
  IconButton,
  IconLink,
  InputFile,
  InputFileSmall,
  InstructionsList,
  Legend,
  Main,
  NavBar,
  Paragraph,
  ProgressionIndicator,
  Text,
  UseIcon,
  ValidationFeedback,
} from '@domain/components';
import {
  browserStorage,
  goToNextPage,
} from '@domain/helpers';
import { HTTP_ERROR_ENCOUNTERED } from '@domain/action-types';
import { useNavigate } from 'react-router-dom'
import {
  useFaq,
  useEnterKey,
  useDragAndDrop,
  useFilesEndpoint,
  useScaling
} from '@domain/hooks';

import { isMobile } from 'react-device-detect';
import { Colors } from '@domain/theming';
import { Box } from '@mui/material';

const StyledMain = styled(Main, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  .uploads__validation {
    min-height: 50px;
    margin-top: 0;
  }
  .image-uploads__title {
    flex: 1;
  }
  .image_uploads-feedback-comment {
    margin-bottom: 30px;
    margin-top: 15px;
    .h4 {
      color: ${Colors.RED}
    }
    .feedback-comment {
      margin-top: 0;  
      color: ${Colors.RED}
    }
  }
  .small__image {
    cursor: pointer;
    max-width: ${props => props.$scaling.scaleUpTo4K(250)}px;
    margin-top: ${props => props.$scaling.scaleUpTo4K(10)}px;
    height: auto;
    max-height: 180px; // IE bug -- big images brake from flex flow
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .screen-desktop & {
    .grid-container__main {
      justify-content: space-between;
    }
    .grid__column--main {
      flex: 0 1 50%;
      padding-right: ${props => props.$scaling.scaleUpTo4K(80)}px;
      padding-bottom: ${props => props.$scaling.scaleUpTo4K(80)}px;
       @media screen and (max-width: 1028px) and (min-width: 581px) {
                align-items:center;
              }
    }
    .grid__column--footer {
      align-items: stretch;
      max-width: ${props => props.$scaling.scaleUpTo4K(500)}px;
      padding-top: ${props => props.$scaling.scaleUpTo4K(40)}px;
      padding-left: ${props => props.$scaling.scaleUpTo4K(50)}px;
    }
    .instructions--list {
      min-height: ${props => props.$scaling.scaleUpTo4K(300)}px;
    }

    .image-upload__button-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-end;
    }

    .image-upload__title {
      // max-width: ${props => props.$scaling.scaleUpTo4K(480)}px;
      // min-height: ${props => props.$scaling.scaleUpTo4K(300)}px;
      margin-bottom: auto;
      .input-custom {
        flex: 1 1 auto;
        padding-bottom: ${props => props.$scaling.scaleUpTo4K(20)}px;
      }
    }
    .uploads__validation {
      padding-bottom: ${props => props.$scaling.scaleUpTo4K(10)}px;
      min-height: ${props => props.$scaling.scaleUpTo4K(50)}px;
    }
  }

  @media screen and (max-width: 1028px) {
    .screen-desktop & {
      .image-upload__title {
        margin-top: 0;
        margin: 0 auto;
      }
      .instructions--list {
        text-align: left;
        margin: 60px auto 0;
        min-height: 320px;
      }
      .grid__column--main {
        padding-right: 0;
        margin: 0 auto;
      }
      .grid__column--footer {
        flex-direction: column;
        padding-top: 0;
        padding-left: 0;
        margin: 0 auto;
        flex: 1 0 auto;
        .image-upload__uploader-container {
          max-width: 490px;
          width: 100%;
          margin: 0 auto;
        }
        .image-upload__button-container {
          max-width: 500px;
          width: 100%;
          margin: 0 auto;
          align-items: center;
        }
      }
    }
    .mobile {
          display: flex;
          flex-direction: column;
          align-items: center; 
          text-align: center;
      }
  }

  @media screen and (max-width: 580px) {
    .screen-desktop & {
      .instructions--list {
        min-height: 250px;
        max-width: none;
        width: 100%;
      }
      .grid__column--footer {
        .image-upload__button-container {
          flex-direction: row;
          justify-content: flex-end;
          max-width: 100%;
        }
      }
    }
     .mobile {
          display: flex;
          flex-direction: column;
          align-items: flex-start; 
          text-align: left;
      }
  }
`;

const StyledList = styled('div', {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
  > div {
    
  }
  ul {
    li {
      span {
        font-size: ${props => !isMobile ? props.$scaling.textSize(16) : props.$scaling.textSizeMobile(16)}px;
        line-height: ${props => !isMobile ? props.$scaling.textSize(20) : props.$scaling.textSizeMobile(20)}px;
      }
    }
  }

  .screen-desktop & {
    > div {
      max-height: ${props => props.$scaling.scaleUpTo4K(125)}px;
    }
    .scale {
      min-height: ${props => props.$showExtraFormField ? '0' : props.$scaling.scaleUpTo4K(88)}px;
    }
  }
`;

const StyledValidationFeedback = styled(ValidationFeedback)`
  display: block;
  text-align: center;
  .screen-desktop .grid__column--footer &.validation-feedback {
    width: auto;
  }
`;

const StyledPlusIcon = styled(UseIcon)`
  opacity: 0.5;
  color: ${Colors.GREY_DARKER};
`;

const StyledGridColumn = styled(GridColumn, {
  shouldForwardProp: (propName) => !propName.startsWith('$')
})`
      align-items: flex-end;
      padding-top: ${props => props.$scaling.scaleUpTo4K(40)}px;
      padding-left: ${props => props.$scaling.scaleUpTo4K(50)}px;
       @media screen and (max-width: 1028px) {
        align-items:center;
       padding-left: 0px;
       padding-top: 0px;
      }
`
function FilesUploadPage({
  buttonText,
  instructions,
  legend,
  heading,
  filesSetName,
  currentPath,
  questionnaire,
  filesSet,
  feedbackCause,
  nextPage,
  prevPage,
  saveIncident,
  validationSchema,
  firstParagraph,
  secondParagraph,
  mobileParagraph,
  faqs,
  isFeedback,
  files,
  incident,
  insurer,
  isPageBeforeSuccess,
  showExtraFormField = false,
  extraFormField,
  customHandleOnClick,
  customNextButtonEnabled,
  showDesktopParagraphOnMobile,
  customHelpLink,
  helpLinkText,
  customMiddleLogo = false,
  middleLogo,
  multiple = true,
  extraLeftBlock,
  dialogMobile,
  faqsMobile
}) {
  const scaling = useScaling();
  const [
    faqVisible,
    handleOnHelpClick,
    clickOutSideFaq,
    faqListProps,
    faqSeen,
  ] = useFaq();
  const navigate = useNavigate()
  const {
    uploadFiles,
    deleteFile,
    bigFileUpload,
    nonAcceptedFileType,
    numOfUploads,
    largeFileUpload,
  } = useFilesEndpoint(filesSetName)


  const [errors, setErrors] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [feedbackValidated, setFeedbackValidated] = useState(incident.feedbackValidated || false);

  const inputFileRef = useRef(null)

  const addUploads = async (event) => {
    event.preventDefault();
    if (event.type === 'drop') {
      uploadFiles(event.dataTransfer.files, incident);
      inputFileRef.current.value = null
    } else {
      uploadFiles(event.target.files, incident);
      inputFileRef.current.value = null
    }
  }

  const isDragging = useDragAndDrop(addUploads);

  function clickMain(event) {
    clickOutSideFaq(event);
  }

  const nextButtonEnabled = showExtraFormField
    ? customNextButtonEnabled && !errors
    : !errors
      ? (isFeedback ? feedbackValidated : true) : false;

  useEffect(() => {
    if (!!questionnaire && questionnaire.length) {
      const progress = questionnaire.find(page => page.path === currentPath)
      browserStorage.setProgress(progress.name);
      browserStorage.setProgressIndex(progress.index);
    }
  }, [currentPath, questionnaire])

  useEffect(() => {
    async function checkForErrors() {
      if (isFeedback) {
        try {
          setErrors(null);
        } catch ({ errors }) {
          setErrors(errors);
        } finally {
          if (!feedbackValidated) {
            setErrors(err => err ? ['Error message', ...err] : ['Upload tenminste 1 nieuwe bestand om verder te gaan']);
          }
        }
      } else {
        try {
          await validationSchema.validate(filesSet, { abortEarly: true });
          setErrors(null);
        } catch ({ errors }) {
          setErrors(errors);
        }

      }
    }
    if (filesSet) {
      const feedbackFileUploaded = filesSet.find(document => document.status === 'FeedbackStored')
      if (feedbackFileUploaded) {
        setFeedbackValidated(true);
      }
      if (feedbackValidated && !feedbackFileUploaded) {
        setFeedbackValidated(false);
      }
    }
    checkForErrors();
  }, [feedbackValidated, filesSet, isFeedback, validationSchema]);

  const dispatch = useDispatch()

  async function handleOnClick(event) {
    setButtonClicked(true);
    if (!errors) {
      if (customHandleOnClick) {
        customHandleOnClick(event)
      } else {
        const incidentSaved = await saveIncident({ ...incident }, isPageBeforeSuccess)
        if (incidentSaved) {
          if (isFeedback && isPageBeforeSuccess) {
            const feedbackValidated = incidentSaved.feedbackValidated
            feedbackValidated
              ? goToNextPage(navigate, questionnaire, incidentSaved, currentPath, files, isFeedback)
              : dispatch({ type: HTTP_ERROR_ENCOUNTERED, error: { message: 'Feedback not validated' } })
            return
          } else {
            goToNextPage(navigate, questionnaire, incidentSaved, currentPath, files, isFeedback);
            return
          }
        }
      }
    }
  }

  useEnterKey(customHandleOnClick ? customHandleOnClick : handleOnClick, [errors]);

  const main = {
    faqVisible: faqVisible,
    dimmed: faqVisible || isDragging,
    className: isDragging
      ? 'main__uploads main__is-dragging'
      : 'main__uploads',
    callBack: clickMain,
    $scaling: scaling,
  };

  const iconButton = {
    type: faqVisible ? 'arrow-right' : 'questionmark',
    className: faqSeen ? '' : 'unclicked-faq-button',
    theme: 'secondary',
    onClick: handleOnHelpClick,
    faq: true,
  };

  const button = {
    theme: nextButtonEnabled ? 'primary' : 'disabled',
    onClick: handleOnClick,
    justify: 'space-between',
    shadow: true,
    className: 'image-upload__button button--icon',
  };

  const iconLink = (triggerKey) => ({
    type: 'arrow-left',
    theme: 'secondary',
    to: prevPage,
    replace: true,
    triggerKey
  });

  const iconLinkForward = (triggerKey) => ({
    type: 'arrow-right',
    theme: 'secondary',
    to: nextPage,
    replace: true,
    triggerKey
  });

  const modifyInstruction = (instruction, i) => ({
    ...instruction,
    title: `Bestand ${i + 1}: ${instruction.title}`,
  });

  const chooseMiddleComponent = customMiddleLogo
    ? <CompanyLogo {...middleLogo} />
    : isFeedback
      ? <Container className="empty-div" />
      : <ProgressionIndicator steps={3} current={2} />

  const uploadsBlock = showExtraFormField
    ? (<Container className="offer--input">
      <InputFileSmall onChange={addUploads} isDragging={isDragging} inputRef={inputFileRef} multiple={multiple} accepts={"image/jpeg,image/jpg,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"}>
        {isDragging ? (
          <StyledPlusIcon
            name="upload-plus"
            className="uploads__input__plus"
          />
        ) : (
          <>
            <UseIcon name="icon-file" />
            <Text>
              <b>Drag & drop om te uploaden</b>
            </Text>
            <Text>Of kies uit de bibliotheek</Text>
          </>
        )}
      </InputFileSmall>
    </Container>)
    : <InputFile multiple={multiple} onChange={addUploads} isDragging={isDragging} inputRef={inputFileRef} accepts={"image/jpeg,image/jpg,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"}>
      {isDragging ? (
        <StyledPlusIcon
          name="upload-plus"
          height={80}
          width={80}
          className="uploads__input__plus"
        />
      ) : (
        <>
          <UseIcon name="icon-file" />
          <Text>
            <b>Drag & drop om te uploaden</b>
          </Text>
          <Text>Of kies uit de bibliotheek</Text>
        </>
      )}
    </InputFile>

  if (isMobile) {
    return (
      <React.Fragment>
        <FAQ
          faqListProps={faqListProps}
          faqs={faqsMobile || faqs}
          faqLink={insurer.helpcenterLink}
          customHelpLink={customHelpLink}
          helpLinkText={helpLinkText}
          faqVisible={faqVisible}
        />
        <StyledMain {...main}>
          <NavBar>
            <Container className="icon-link-container">
              {prevPage && <IconLink {...iconLink([37])} />}
              {nextPage && <IconLink {...iconLinkForward([39])} />}
            </Container>
            {chooseMiddleComponent}
            <Container className="faq-button-container">
              <IconButton {...iconButton} size="large" />
            </Container>
          </NavBar>
          <GridContainer main={true}>
            <GridColumn className="image-uploads__title mobile">
              <Legend>{legend}</Legend>
              <Heading level="1">{heading}</Heading>
              {!!feedbackCause &&
                <Container className="image_uploads-feedback-comment">
                  <Heading level="4">Opmerking van de hersteller</Heading>
                  <Paragraph className="feedback-comment">
                    {feedbackCause}
                  </Paragraph>
                </Container>}
              {showDesktopParagraphOnMobile &&
                <React.Fragment>
                  <Paragraph>
                    {firstParagraph}
                  </Paragraph>
                  {secondParagraph && <Paragraph>
                    {secondParagraph}
                  </Paragraph>}
                </React.Fragment>
              }
              {mobileParagraph && <Paragraph
                onClick={handleOnHelpClick}
                className="image-uploads__help"
              >
                {mobileParagraph}
              </Paragraph>}
              {dialogMobile && dialogMobile}
              {showExtraFormField && extraFormField}
            </GridColumn>
            <StyledGridColumn
              justify="flex-end"
              className="grid__column--main"
              $scaling={scaling}
            >
              <InputFile multiple={multiple} onChange={addUploads} inputRef={inputFileRef} accepts={"image/jpeg,image/jpg,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"}>
                <UseIcon name="icon-file" />
                <Text>
                  <b>Maak een foto om te uploaden</b>
                </Text>
                <Text>Of kies uit de bibliotheek</Text>
              </InputFile>
              <Container className="uploads__validation">
                {errors &&
                  buttonClicked &&
                  errors.map(error => (
                    <StyledValidationFeedback type={buttonClicked ? 'error' : 'info'} key={error}>{error}</StyledValidationFeedback>
                  ))}
                {largeFileUpload && (
                  <StyledValidationFeedback>
                    Bestand's moeten 10MB of minder zijn
                  </StyledValidationFeedback>
                )}
                {nonAcceptedFileType && (
                  <StyledValidationFeedback>
                    Upload alleen jpg-, png-, pdf-, doc- of docx-bestanden
                  </StyledValidationFeedback>
                )}
                {bigFileUpload && numOfUploads < 1 && (
                  <StyledValidationFeedback>
                    Even geduld a.u.b.<br /><br />
                    Het uploaden van grote bestanden kan even duren.
                  </StyledValidationFeedback>
                )}
              </Container>
              <StyledList $scaling={scaling}>
                <AttachmentViewer files={[...filesSet]} onClickDelete={(file) => {
                  inputFileRef.current.value = null
                  deleteFile(file);
                }} />
              </StyledList>
            </StyledGridColumn>
            <GridColumn className="grid__column--footer">
              <Button {...button}>
                {buttonText || "Volgende"}
                <UseIcon name="arrow-right" className="button__icon" />
              </Button>
            </GridColumn>
          </GridContainer>
        </StyledMain>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <FAQ
        faqListProps={faqListProps}
        faqs={faqs}
        faqLink={insurer.helpcenterLink}
        customHelpLink={customHelpLink}
        helpLinkText={helpLinkText}
        faqVisible={faqVisible}
      />
      <StyledMain {...main}>
        <NavBar>
          <Container className="icon-link-container">
            {prevPage && <IconLink {...iconLink([37])} />}
            {nextPage && <IconLink {...iconLinkForward([39])} />}
          </Container>
          {chooseMiddleComponent}
          <Container
            className="faq-button-container"
            onClick={handleOnHelpClick}
          >
            <IconButton {...iconButton} size="large" />
          </Container>
        </NavBar>
        <GridContainer main={true}>
          <GridColumn
            justify="space-evenly"
            className="grid__column--main"
          >
            <Container className="image-upload__title">
              <Legend>{legend}</Legend>
              <Heading level="1">{heading}</Heading>
              {!!feedbackCause && <Container className="image_uploads-feedback-comment">
                <Heading level="4">Opmerking van de hersteller</Heading>
                <Paragraph className="feedback-comment">
                  {feedbackCause}
                </Paragraph>
              </Container>}
              <Paragraph>
                {firstParagraph}
              </Paragraph>
              {secondParagraph && <Paragraph>
                {secondParagraph}
              </Paragraph>}
            </Container>
            {instructions && <InstructionsList
              instructions={instructions.map(modifyInstruction)}
            />}
            {extraLeftBlock && extraLeftBlock}
          </GridColumn>

          <StyledGridColumn
            $scaling={scaling}
          >
            <Box sx={{ maxWidth: `${scaling.scaleUpTo4K(500)}px`, width: '-webkit-fill-available' }}>
              <Container className="image-upload__uploader-container">
                {showExtraFormField && extraFormField}
                <StyledList $scaling={scaling} $showExtraFormField={showExtraFormField}>
                  <AttachmentViewer files={[...filesSet]} onClickDelete={(file) => {
                    inputFileRef.current.value = null
                    deleteFile(file);
                  }} />
                </StyledList>
                {uploadsBlock}
                <Container className="uploads__validation">
                  {errors &&
                    errors.map(error => (
                      <StyledValidationFeedback
                        type={buttonClicked ? 'error' : 'info'}
                        key={error}
                      >
                        {error}
                      </StyledValidationFeedback>
                    ))}
                  {largeFileUpload && (
                    <StyledValidationFeedback>
                      Bestand's moeten 10MB of minder zijn
                    </StyledValidationFeedback>
                  )}
                  {nonAcceptedFileType && (
                    <StyledValidationFeedback>
                      Upload alleen jpg-, png-, pdf-, doc- of docx-bestanden
                    </StyledValidationFeedback>
                  )}
                  {bigFileUpload && (
                    <StyledValidationFeedback>
                      Even geduld a.u.b.<br /><br />
                      Het uploaden van grote bestanden kan even duren.
                    </StyledValidationFeedback>
                  )}
                </Container>
              </Container>
              <Container className="image-upload__button-container">
                {' '}
                <Button {...button}>
                  {buttonText || 'Volgende'}
                  <UseIcon name="arrow-right" className="button__icon" />
                </Button>
                <Text className="of--enter">
                  of <b>ENTER</b>
                </Text>
              </Container>
            </Box>
          </StyledGridColumn>
        </GridContainer>
      </StyledMain>
    </React.Fragment>
  );
}

export default FilesUploadPage
